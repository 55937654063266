import React, {useState,useEffect, useRef} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import SquadPay from "react-squadpay"



function Home(){
  const history = useHistory();
  const orderURL = "https://backend.shecosmetiics.com/api/createOrder";
  const [totaldistrictamount, setTotalDistrictAmount] = useState(0);
  const MySwal = withReactContent(Swal)
  const [isHidden, setIsHidden] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [state , setState] = useState({
   f_name: '',
    email: '',
    no_of_glosses: '1',
    address: '',
    district: '',
    phone: '',
    comments: '',

})

let axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};

const handlesChange = (e) => {
  const { name, value } = e.target;
  setState(prevState => ({
    ...prevState,
    [name]: value
  }));
};


const saveDataToLocalStorage = (data) => {
  try {
    const jsonData = JSON.stringify(data);
    localStorage.setItem('shecosmeticsorderData', jsonData);
    console.log('Data saved to localStorage');
  } catch (error) {
    console.error('Error saving data to localStorage', error);
  }
};

useEffect(() => {
  let amount = 0;
  switch (state.district) {
    case 'Lekki 1':
      amount = 3500;
      break;
    case 'Ikate':
      amount = 2700;
      break;
    case 'Osapa':
      amount = 2700;
      break;
    case 'Chevron':
      amount = 2700;
      break;
    case 'Ikota':
      amount = 2700;
      break;
    case 'YABA':
      amount = 3000;
      break;
      case 'Sangotedo':
        amount = 4000;
        break;
      case 'MAINLAND':
      amount = 3500;
      break;
      case 'Ikeja':
      amount = 3500;
      break;
    case 'Ajah':
      amount = 2700;
      break;
    case 'Ikoyi':
      amount = 4000;
      break;
    case 'VI':
      amount = 4000;
      break;
    case 'LAGOS':
      amount = 3500;
      break;
    default:
      amount = 0;
  }
  setTotalDistrictAmount(amount);
}, [state.district]);





const handleChange = (e) => {
const {id , value} = e.target   
setState(prevState => ({
    ...prevState,
    [id] : value
}))
}

var stotal = Number(10000)* Number(state.no_of_glosses);
var subtotal = stotal.toLocaleString();
var Totl = Number(stotal)+Number(totaldistrictamount);
var servicecharge = Totl * Number(0.012)
var realservicecharge = servicecharge.toLocaleString();
var newtotal = Math.round(Number(servicecharge) + Number(Totl));
var Total = Math.round(newtotal).toLocaleString();



var postData = {
  "accesstoken": 'STYMBX4Z5J2P9K6L3T8Q',
  "customername": state.f_name,
  "customeremail": state.email,
  "OrdertotalQuantity": state.no_of_glosses,
  "customeraddress": state.address,
  "customeraddressregion": state.district,
  "customerphone": state.phone,
  "ordernotes": state.comments,
  "OrdertotalAmount": Total,
  "reference": state.reference,
  "PaymentStatus": 'Completed',
  "Ordername": 'Love & Light Collection',
  "OrderdeliveryAmount" : totaldistrictamount
};


const params = {
  key: "pk_3fb5aff08bdea5bf65aa27ac10c9e78c5616ba77",
  email: state.email, // from HTML form
  amount: newtotal, 
  customer_name	: state.f_name, 
  callbackUrl: 'https://shecosmetiics.com',
  currencyCode: "NGN",
  payment_channels: 'card'
}


const Close = () => {
  console.log("Widget closed")
}

const Load = () => {
  console.log("Widget Loaded")
console.log(params)

}

/**
 * @param {object} data
 * @description  reponse when payment is successful
 */


const Success = (data) => {
  console.log(data)
  console.log("Widget success")
  const paymentReference = data.reference; // Get the reference from the payment data
  console.log(`Payment reference: ${paymentReference}`);
  createOrder();
  // window.location.href = `https://shecosmetiics.com/paymentsuccess?reference=${paymentReference}`;
  // window.location.href = `http://localhost:3000/paymentsuccess?reference=${paymentReference}`;
}

// const PostData = localStorage.getItem('shecosmeticsorderData');
// const storedPostData = JSON.parse(PostData);

const createOrder = async () => {
if (!postData) {
  Swal.fire({
    text: "Error in recovering information",
    icon: "error",
    showCancelButton: false,
    confirmButtonText: "Okay",
    confirmButtonColor: "#ea3989", 
    allowOutsideClick: false,
    allowEscapeKey: false,
    closeOnCancel: true,
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = '/';
    }
  });
} else {
  try {
    console.log(postData)
    MySwal.fire({
      title: <p>please wait...</p>,
      didOpen: async () => {
        try {
          const response = await axios.post(orderURL, postData, axiosConfig);
          console.log("response received:", response);
          Swal.fire({
            text: 'Order Completed',
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#ea3989", 
            allowOutsideClick: false,
            allowEscapeKey: false,
            closeOnCancel: true,
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(response);
              window.location.href = '/';
              localStorage.removeItem('shecosmeticsorderData');
            }
          });
        } catch (error) {
          console.log("AXIOS ERROR:", error);
          Swal.fire({
            text: error.message || "An error occurred",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#ea3989", 
            allowOutsideClick: false,
            allowEscapeKey: false,
            closeOnCancel: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
              localStorage.removeItem('shecosmeticsorderData');
            }
          });
        }
        
        
      },
    });
  } catch (error) {
    console.error('Error creating order:', error);
  }
}
};

const formatNumber = (value) => {
  if (!value) return '';
  // Remove any non-digit characters except for the decimal point
  const sanitizedValue = value.replace(/[^\d.]/g, '');

  // Split the number into the integer and decimal parts
  let [integerPart, decimalPart] = sanitizedValue.split('.');

  // Remove leading zeros from the integer part, unless it's zero (e.g., "0.123" should stay "0")
  integerPart = integerPart.replace(/^0+(?=\d)/, '');

  // Format the integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the formatted number, maintaining any existing decimal part
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const handleNumberInputChange = (e) => {
  const { id, value } = e.target;

  // Remove commas for the raw value to be stored in state
  const rawValue = value.replace(/,/g, '');

  // Format the value with commas for display
  const formattedValue = formatNumber(rawValue);

  // Update the state with the raw value (no commas)
  setState((prevState) => ({
    ...prevState,
    [id]: rawValue, // Store the raw value without commas
  }));

  // Update the input field with the formatted value
  e.target.value = formattedValue;
};
  


const placeOrder = (e) => {
  if (!state.f_name || !state.email || !state.no_of_glosses || !state.phone || !state.address) {
    MySwal.fire({
      text: 'Please fill all fields',
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    setShow(true)
    saveDataToLocalStorage(postData);
  }
};

      return (
        <div className="wrapper">
        <header className="header-area sticky-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-5 col-sm-6 col-lg-3">
                <div className="header-logo">
                  <a href="#home">
                    <img className="logo-main" src="assets/images/2.png" width={125} height={88} alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 d-none d-lg-block">
                <div className="header-navigation">
                  <ul className="main-nav justify-content-start">
                    <li><a style={{color: '#ea3989'}} href="#home">home</a>
                    </li>
                    <li><a href="#about">about SHE</a></li>
                    <li><a href="#shop">shop</a>
                    </li>
                    <li><a href="#contact">Contact</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="main-content">
          <section className="hero-two-slider-area position-relative">
            <div className="swiper hero-two-slider-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide hero-two-slide-item">
                  <div className="container">
                    <div className="row align-items-center position-relative">
                      <div className="col-12 col-md-6">
                        <div className="hero-two-slide-content">
                          <h4 className="hero-two-slide-title" style={{color: '#ea3989'}}>SHE COSMETICS</h4>
                          <p className="hero-two-slide-desc" style={{color: '#ea3989'}}>Mirror-worthy lip perfection</p>
                          <p className="hero-two-slide-desc" style={{color: '#ea3989 !important'}}>Explore our Love &amp; Light collection designed to elevate your look.</p>
                          <div className="hero-two-slide-meta">
                            <a className="btn btn-border-primary"  href='#shop'>SHOP</a>
                          </div>
                          <br/>
                          <br/>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="hero-two-slide-thumb">
                          <img className='homeimg' src="assets/images/3.jpeg" width={590} height={490} alt="Image" />
                        </div>
                        <br/>
                      <br/>
                      <br/>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-two-slider-pagination" />
            </div>
          </section>
          <section className="section-space removeimg">
            <div className="container">
              <div className="row side-by-side">
                <div className="col-sm-12 col-lg-6 mt-sm-0 mt-6">
                  <a href="#" className="product-banner-item" >
                    <img src="assets/images/5.jpeg"  alt="SHE Cosmetics" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="section-space pt-20" id='about'>
            <div className="container">
              <div className="row">
                <div className="col-lg-6" >
                  <div >
                    <h4 className="title" style={{color: '#ea3989'}}>About SHE Cosmetics</h4>
                  </div>
                  <p className="m-0" style={{color: '#ea3989', textAlign: 'justify'}}> SHE Cosmetics, where beauty meets confidence!<br />We believe that every individual deserves to feel empowered and beautiful in their own skin. Our passion for beauty drives us to create high-quality lip gloss products that not only enhance your natural features but also inspire self-expression. <br /><br />We are a community of like-minded individuals who celebrate individuality and diversity. Through our inclusive approach to beauty, we strive to create a space where everyone feels valued and represented.</p>
                </div>
                <div className="col-lg-6">
                  <div className="section-title">
                    <img src="assets/images/9.jpeg" style={{borderRadius: '20px', width: '100%'}} />
                  </div>
                </div>
              </div>
            </div>
          </section>


      <section className="shopping-checkout-wrap section-space" id='shop'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="checkout-billing-details-wrap">
              <h1 className="title" style={{color: '#ea3989'}}>Shop SHE Cosmetics</h1>
                <h6>Enter billing details</h6>
                <small style={{color: '#ea3989', fontSize: '12px', marginTop: '-50px'}}>For locations outside Lagos, Nigeria. Please contact us on any of our social media channels or via email to shop@shecosmetiics.com.</small>
                <br/>
                <br/>
                <div className="billing-form-wrap">
                  <form action="#" method="post">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="f_name">Full name *</label>
                          <input value={state.f_name} onChange={handleChange} id="f_name" name="f_name" type="text" className="form-control" />
                        </div>
                      </div>
                     
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="com_name">Email Address <span style={{color: 'red'}}>*</span></label>
                          <input value={state.email} onChange={handleChange} id="email" name="email" type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-group">
                          <label htmlFor="country">No of Glosses <abbr className="required" title="required">*</abbr></label>
                          <input value={formatNumber(state.no_of_glosses)} onChange={handleNumberInputChange} id="no_of_glosses" name="no_of_glosses" type="number" className="form-control" placeholder="e.g. 2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="street-address">Full address <abbr className="required" title="required">*</abbr></label>
                          <input value={state.address} onChange={handleChange} id="address" name="address" type="text" className="form-control" placeholder="House number and street name" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                         <div className="form-group">
                          <label htmlFor="district">Region within Lagos <abbr className="required" title="required">*</abbr></label>
                          <select value={state.district} onChange={handlesChange} id="district" name="district" className="form-control">
                            <option value=' '>--select--</option>
                            <option value='Lekki 1'>Lekki Phase 1</option>
                            <option value='Osapa'>Osapa</option>
                            <option value='Chevron'>Chevron</option>
                            <option value='Ajah'>VGC/Ajah</option>
                            <option value='Sangotedo'>Sangotedo/Badore</option>
                            <option value='Ikoyi'>Ikoyi</option>
                            <option value='VI'>Victoria Island</option>
                            <option value='LAGOS'>Lagos Island</option>
                            <option value='Ikeja'>Ikeja</option>
                            <option value='YABA'>Yaba/Surulere/Gbagada</option>
                            <option value='MAINLAND'>All Other mainland</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone">Phone <abbr className="required" title="required">*</abbr></label>
                          <input value={state.phone} onChange={handleChange} id="phone" name="phone" type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          <label htmlFor="order-notes">Order notes (optional)</label>
                          <textarea value={state.comments} onChange={handleChange} id="comments" name="comments" className="form-control" placeholder="Notes about your order, e.g. special notes for delivery." defaultValue={""} />
                        </div>
                      </div>
                    </div>
                  </form>
                  <br/>
                  <button onClick={placeOrder} style={{backgroundColor: '#ea3989'}} className="btn btn-place-order">Place order</button>
              </div>
              </div>
            </div>
            <div className="col-lg-6" >
              {/* <br/> */}
                  <img src="assets/images/15.png" alt="Logo" />
            
             
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><i onClick={handleClose} style={{textAlign: 'right', color: '#ea3989'}} className='fa fa-close'></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div className="checkout-order-details-wrap">
                <div className="order-details-table-wrap table-responsive">
                  <h4 className="title mb-25" style={{color: '#ea3989'}}>Your order</h4>
                  <br/>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="product-name">Product</th>
                        <th className="product-total">Total</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      <tr className="cart-item">
                        <td className="product-name">Love & Light Lip Gloss <span className="product-quantity">× {state.no_of_glosses}</span></td>
                        <td className="product-total">₦ {(Number(10000) * Number(state.no_of_glosses)).toLocaleString()}</td>
                      </tr>
                     
                    </tbody>
                    <tfoot className="table-foot">
                      <tr className="cart-subtotal">
                        <th>Subtotal</th>
                        <td>₦ {subtotal}</td>
                      </tr>
                      <tr className="shipping">
                        <th>Delivery</th>
                        <td>{state.district}: ₦ {totaldistrictamount.toLocaleString()}</td>
                      </tr>
                      <tr className="shipping">
                        <th>Service Charge</th>
                        <td>₦  {realservicecharge}</td>
                      </tr>
                      <tr className="order-total">
                        <th>Total </th>
                        <td>₦ {Total}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="shop-payment-method">
                    <div id="PaymentMethodAccordion">
                      <div className="card">
                        <div id="itemOne" className="collapse show" aria-labelledby="check_payments" data-bs-parent="#PaymentMethodAccordion">
                          <div className="card-body">
                            <p>Make your payment by clicking below. An Order ID would be sent to the provided email address. <br/> Your order will be arranged to be delivered as soon as the payment is processed.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SquadPay style={{backgroundColor: '#ea3989 !important'}} className="btn-sm btn" text='Pay now'  params={params} onClose={Close} onLoad={Load} onSuccess={(res)=>Success(res)} />
                  </div>
                </div>
              </div>
          </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
        </main>
        <footer className="footer-area" id='contact'>
          <div className="footer-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="widget-item">
                    <div className="widget-about">
                      <a className="widget-logo" href="index.html">
                        <img src="assets/images/2.png" width={145} alt="Logo" />
                      </a>
                      <p className="desc">Elevate your look with Love & Light by SHE.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-5 mt-md-0 mt-9">
                  <div className="widget-item">
                    <h4 className="widget-title">Information</h4>
                    <ul className="widget-nav">
                      <li><a href="#about">About us</a></li>
                      <li><a href="#shop">Shop</a></li>
                      <li><a href="#contact">Contact</a></li>
                      <li><a href="/privacy">Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mt-lg-0 mt-6">
                  <div className="widget-item">
                    <h4 className="widget-title">Social Info</h4>
                    <div className="widget-social">
                      <a href="https://instagram.com/she.cosmetiics" target="_blank" rel="noopener"><i className="fa fa-instagram" /></a>
                      <a href="https://www.facebook.com/shecosmetics" target="_blank" rel="noopener"><i className="fa fa-facebook" /></a>
                      <a href="https://www.pinterest.com/shecosmetiics" target="_blank" rel="noopener"><i className="fa fa-google" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container pt-0 pb-0">
              <div className="footer-bottom-content">
                <p className="copyright">© 2024 SHE Cosmetics</p>
              </div>
            </div>
          </div>
        </footer>
        <div id="scroll-to-top" className="scroll-to-top"><span className="fa fa-angle-up" /></div>
      </div>
               
        );
 
  };

export default Home;
