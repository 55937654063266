import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Home from './component/transactions/home';
import Paymentsuccess from './component/transactions/paymentsuccess';
import Privacy from './component/transactions/privacy';



export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/paymentsuccess" component={Paymentsuccess}/>
          <Route exact path="/privacy" component={Privacy}/>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

